import {
  WAIT_RELATION,
  WAIT_GENERATE_WAYBILL,
  EXPIRED,
  WAIT_UPLOAD_EXPRESS,
  WAIT_UPLOAD_PACKING,
  UPLOADED_PACKING,
  WAIT_SEND_GOODS,
  IN_DISPATCH,
  IN_PRODUCTION,
  DELIVER,
  RETURN_GOODS,
  RETURNED,
  CANCEL,
  FINISH,
  WAIT_CONFIRM,
  WAIT_PAYMENT,
  PAID,
  PENDING,
  AFTER_COMPLETE
} from '@/utils/constant'
import { checkPermission } from '@/utils'

export const list = [
  {
    label: '全部订单',
    value: '',
    permission: checkPermission('externaladmin:orderManage:orderList:all'),
    childPermission: {
      export: checkPermission('externaladmin:orderManage:orderList:all:export'),
      exportDeclarationInfo: checkPermission('externaladmin:orderManage:orderList:all:exportDeclarationInfo'),
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:all:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:all:search'),
      update: checkPermission('externaladmin:orderManage:orderList:all:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:all:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:all:detail')
    }
  },

  // {
  //   label: '待确认',
  //   value: WAIT_CONFIRM,
  //   searchValue: {
  //     orderStatus: WAIT_CONFIRM
  //   },
  //   permission: checkPermission('externaladmin:orderManage:orderList:waitConfirm'),
  //   childPermission: {
  //     downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitConfirm:downloadPdf'),
  //     search: checkPermission('externaladmin:orderManage:orderList:waitConfirm:search'),
  //     update: checkPermission('externaladmin:orderManage:orderList:waitConfirm:update'),
  //     edit: checkPermission('externaladmin:orderManage:orderList:waitConfirm:edit'),
  //     detail: checkPermission('externaladmin:orderManage:orderList:waitConfirm:detail')
  //   }
  // },
  {
    label: '待关联',
    value: WAIT_RELATION,
    searchValue: {
      orderStatus: WAIT_RELATION
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitRelation'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitRelation:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitRelation:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitRelation:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitRelation:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitRelation:detail')
    }
  },
  {
    label: '待生成运单',
    value: WAIT_GENERATE_WAYBILL,
    searchValue: {
      orderStatus: WAIT_GENERATE_WAYBILL
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitCreateWaybill:detail')
    }
  },
  {
    label: '待付货款',
    value: WAIT_PAYMENT,
    searchValue: {
      orderStatus: WAIT_PAYMENT
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitPayment'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitPayment:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitPayment:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitPayment:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitPayment:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitPayment:detail')
    }
  },
  {
    label: '子账号审核订单',
    value: PAID,
    searchValue: {
      orderStatus: PAID
    },
    permission: checkPermission('externaladmin:orderManage:orderList:paid'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:paid:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:paid:search'),
      update: checkPermission('externaladmin:orderManage:orderList:paid:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:paid:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:paid:detail')
    }
  },
  {
    label: '排单中',
    value: IN_DISPATCH,
    searchValue: {
      orderStatus: IN_DISPATCH
    },
    permission: checkPermission('externaladmin:orderManage:orderList:inDispatch'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:inDispatch:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:inDispatch:search'),
      update: checkPermission('externaladmin:orderManage:orderList:inDispatch:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:inDispatch:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:inDispatch:detail')
    }
  },

  {
    label: '生产中',
    value: IN_PRODUCTION,
    searchValue: {
      orderStatus: IN_PRODUCTION
    },
    permission: checkPermission('externaladmin:orderManage:orderList:inProduction'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:inProduction:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:inProduction:search'),
      update: checkPermission('externaladmin:orderManage:orderList:inProduction:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:inProduction:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:inProduction:detail')
    }
  },
  {
    label: '待选择物流',
    value: WAIT_UPLOAD_EXPRESS,
    searchValue: {
      orderStatus: WAIT_UPLOAD_EXPRESS
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitSelectExpress:detail')
    }
  },
  {
    label: '待上传装箱单',
    value: WAIT_UPLOAD_PACKING,
    searchValue: {
      orderStatus: WAIT_UPLOAD_PACKING
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitUploadPackingList:detail')
    }
  },
  {
    label: '已上传装箱单',
    value: UPLOADED_PACKING,
    searchValue: {
      orderStatus: UPLOADED_PACKING
    },
    permission: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList:search'),
      update: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:uploadedPackingList:detail')
    }
  },
  {
    label: '待发货',
    value: WAIT_SEND_GOODS,
    searchValue: {
      orderStatus: WAIT_SEND_GOODS
    },
    permission: checkPermission('externaladmin:orderManage:orderList:waitDeliver'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:waitDeliver:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:waitDeliver:search'),
      update: checkPermission('externaladmin:orderManage:orderList:waitDeliver:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:waitDeliver:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:waitDeliver:detail')
    }
  },
  {
    label: '已发货',
    value: DELIVER,
    searchValue: {
      orderStatus: DELIVER
    },
    permission: checkPermission('externaladmin:orderManage:orderList:delivered'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:delivered:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:delivered:search'),
      update: checkPermission('externaladmin:orderManage:orderList:delivered:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:delivered:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:delivered:detail')
    }
  },
  {
    label: '已完成',
    value: FINISH,
    searchValue: {
      orderStatus: FINISH
    },
    permission: checkPermission('externaladmin:orderManage:orderList:finished'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:finished:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:finished:search'),
      update: checkPermission('externaladmin:orderManage:orderList:finished:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:finished:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:finished:detail')
    }
  },

  {
    label: '已过期',
    value: EXPIRED,
    searchValue: {
      orderStatus: EXPIRED
    },
    permission: checkPermission('externaladmin:orderManage:orderList:expired'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:expired:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:expired:search'),
      update: checkPermission('externaladmin:orderManage:orderList:expired:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:expired:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:expired:detail')
    }
  },
  {
    label: '已取消',
    value: CANCEL,
    searchValue: {
      orderStatus: CANCEL
    },
    permission: checkPermission('externaladmin:orderManage:orderList:cancelled'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:cancelled:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:cancelled:search'),
      update: checkPermission('externaladmin:orderManage:orderList:cancelled:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:cancelled:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:cancelled:detail')
    }
  },
  {
    label: '售后待处理',
    value: PENDING,
    searchValue: {
      orderStatus: PENDING
    },
    permission: checkPermission('externaladmin:orderManage:orderList:afterSalePending'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:afterSalePending:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:afterSalePending:search'),
      update: checkPermission('externaladmin:orderManage:orderList:afterSalePending:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:afterSalePending:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:afterSalePending:detail')
    }
  },
  {
    label: '售后完成',
    value: AFTER_COMPLETE,
    searchValue: {
      orderStatus: AFTER_COMPLETE
    },
    permission: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished'),
    childPermission: {
      downloadPdf: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished:downloadPdf'),
      search: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished:search'),
      update: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished:update'),
      edit: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished:edit'),
      detail: checkPermission('externaladmin:orderManage:orderList:afterSaleFinished:detail')
    }
  }
].filter((item) => item.permission)
